import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { userActions } from '../../Reducer';
import { ReactSVG } from 'react-svg';
import  USImage  from '../../assets/US_states.svg'; 
// import  IndiaImage  from '../../assets/US_states.svg'; 

const decodeHTML = function (html: string) {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

function Question(props:any) {
  const [questions, setQuestions] = useState([]);
  const [answerSelected, setAnswerSelected] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [options, setOptions] = useState([]);
  const [image, setImage] = useState('');

  
  const dispatch: AppDispatch = useDispatch();
  const score = useSelector((state: RootState) => state.score); 
  const questionIndex = useSelector((state: RootState) => state.currentScreenIndex);
  const index = useSelector((state: RootState) => state.index);
  const selectedCategory = useSelector((state: RootState) => state.selectedQuestionCategory);
   
  
  const encodedQuestions = props.questions;
  //useSelector((state: RootState) => state.questions);


  useEffect(() => {
    const decodedQuestions:any = encodedQuestions.map((q:any) => {
      return {
        ...q,
        question: decodeHTML(q.question),
        correct_answer: decodeHTML(q.correct_answer),
        incorrect_answers: q.incorrect_answers.map((a:any) => decodeHTML(a))
      }
    })

    setQuestions(decodedQuestions)
  }, [encodedQuestions])

  const question :any = questions[questionIndex];
  const answer = question && question.correct_answer;

  const getRandomInt = (max:any) => {
    return Math.floor(Math.random() * Math.floor(max))
  }

  useEffect(() => {
    if (selectedCategory !== null) {  
        switch (selectedCategory) {
          case 'States':
            setImage(USImage);
            break;
          default:
            break;
        }   
    }
  }, [selectedCategory])

  useEffect(() => {
    if (!question) {
      return;
    }
    let answers:any = [...question.incorrect_answers]
    answers.splice(getRandomInt(question.incorrect_answers.length + 1), 0, question.correct_answer)

    setOptions(answers)
  }, [question])

  const handleListItemClick = (event:any) => {
    setAnswerSelected(true)
    setSelectedAnswer(event.target.textContent)

    if (event.target.textContent === answer) {
      const scoreValue : any = score + 1;
      dispatch(userActions.SET_SCORE(scoreValue));
    }

    if (questionIndex + 1 <= questions.length) {
      setTimeout(() => {
        
        setAnswerSelected(false);
        setSelectedAnswer(null);
        dispatch(userActions.SET_CurrentScreenIndex(questionIndex + 1 as never));
        dispatch(userActions.SET_INDEX(index + 1 as never));

      }, 1500)
    }
  }


  const getClass = (option :any) => {
    if (!answerSelected) {
      return ``;
    }

    if (option === answer) {
      return `correct`
    }

    if (option === selectedAnswer) {
      return `selected`
    }
  }

  if (!question) {
    return <div>Loading</div>
  }

  return (
    <div className='question-container'>

      <h3>{questionIndex + 1}. {question.question}</h3>
      {question.category === 'US States' && <ReactSVG src={image}
        className="svg-container"
        afterInjection={(svg) => {
          let svgPath: any = svg.querySelector(`[id^=${question?.code}]`); // Get the path element);
          if (question?.code === 'dc') {
            svgPath = svg.querySelector(`circle.${question?.code}`);
          }

          if (svgPath) {
            svgPath.style.fill = '#9c27b0'; // Set the color of the path for the question
          }
        }} />}

      <ul className={question.category === 'US States' ? 'row' : 'colomn'}>
        {options.map((option, i) => (
          <li key={i} onClick={(event) => handleListItemClick(event)} className={getClass(option)}>
            {option}
          </li>
        ))}
      </ul>
      {/* <div>
        Score: {score} / {questions.length}
      </div> */}
    </div>
  )
}
export default Question
