// @ts-nocheck
import { configureStore } from '@reduxjs/toolkit'
import quizReducer from './Reducer';
import {
   createStateSyncMiddleware,
   initMessageListener,
 } from "redux-state-sync";

  const store = configureStore({
    reducer:  quizReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(createStateSyncMiddleware({})),
   });
   
   initMessageListener(store);
   export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
// @ts-ignore
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// @ts-ignore
export type AppDispatch = typeof store.dispatch;