
import { createSlice, current } from '@reduxjs/toolkit'
import { RootState } from './store';

// Slice
const quizReducer = createSlice({
    name: 'quiz',
    initialState: {
        options: {
            loading: false,
            question_category: 'All',
            question_difficulty: 'All',
            question_type: ``,
            amount_of_questions: 15
        },
        questions: [],
        index: 0,
        score: 0,
        currentScreenIndex: 0,
        selectedQuestionCategory: null,
        isQuizInProgress: false,
        totalQuestion: 75
    },
    reducers: {
        CHANGE_LOADING: (state, action: any) => {
            state.options.loading = action.value;
        },
        CHANGE_CATEGORY: (state, action: any) => {
            state.options.question_category = action.payload;
        },
        CHANGE_DIFFICULTY: (state, action: any) => {
            state.options.question_difficulty = action.payload;
        },
        CHANGE_TYPE: (state, action: any) => {
            state.options.question_type = action.payload;
        },
        CHANGE_AMOUNT: (state, action: any) => {
            state.options.amount_of_questions = action.payload;
        },
        SET_QUESTIONS: (state, action: any) => {
            state.questions = action.questions;
        },
        SET_INDEX: (state, action: any) => {
            state.index = action.payload;
        },
        SET_SCORE: (state, action: any) => {
            state.score = action.payload;
        },
        SET_SELECTEDCATEGORY: (state, action: any) => {
            state.selectedQuestionCategory = action.payload;
        },
        SET_CurrentScreenIndex: (state, action: any) => {   
            state.currentScreenIndex = action.payload;
        },
        SET_TOTALQUESTIONLENGTH: (state, action: any) => {   
            state.totalQuestion = action.payload;
        },
        IS_QUIZINPROGRESS: (state, action: any) => {    
            state.isQuizInProgress = action.payload;
        } 
    },
    selectors: {
        selectValue: (state) => state
    }
});
// Actions
export const userActions = quizReducer.actions;
export default quizReducer.reducer;
export const getStoreData = ((state: RootState) => current(state));
export const { selectValue } =  quizReducer.selectors;

