import * as React from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

export default function AboutUs() {
    // const [open, setOpen] = React.useState(true);


    // const handleClose = () => {
    //     setOpen(false);
    //     props.reset();
    // };

    return (
        <React.Fragment>
            <div className="about-section">
                <h1>About Us Page</h1>
                <p>Welcome to "Q for Quizzer" app, your ultimate destination for fun and engaging quizzes! Whether you're a trivia buff, a pop culture enthusiast, or just looking to challenge yourself with brain-teasing questions, we've got something for everyone.</p>
                <p>At "Q for Quizzer", we believe that learning should be enjoyable. That's why we've curated a wide range of quiz categories to cater to diverse interests and knowledge levels. From history and science to general knowledge our quizzes cover it all. You can play solo to test your knowledge or challenge your friends and family to see who reigns supreme!</p>
                <p>Our mission is to provide you with an interactive and educational experience that keeps you coming back for more. Each quiz is crafted with care by our team of quiz masters to ensure accuracy, relevance, and most importantly, fun! Whether you're playing to learn something new or just for the thrill of competition, we're here to make sure you have a great time.</p>
                <p>Join our growing community of quiz enthusiasts today and embark on a journey of discovery and entertainment with "Q for Quizzer"!.</p>
            </div>

            {/* <h2 style={text-align:"center"}>Our Team</h2>
<div className="row">
  <div className="column">
    <div className="card">
      <img src="/w3images/team1.jpg" alt="Jane" style="width:100%">
      <div className="container">
        <h2>Jane Doe</h2>
        <p className="title">CEO & Founder</p>
        <p>Some text that describes me lorem ipsum ipsum lorem.</p>
        <p>jane@example.com</p>
        <p><button className="button">Contact</button></p>
      </div>
    </div>
  </div>

  <div className="column">
    <div className="card">
      <img src="/w3images/team2.jpg" alt="Mike" style="width:100%">
      <div className="container">
        <h2>Mike Ross</h2>
        <p className="title">Art Director</p>
        <p>Some text that describes me lorem ipsum ipsum lorem.</p>
        <p>mike@example.com</p>
        <p><button className="button">Contact</button></p>
      </div>
    </div>
  </div>

  <div className="column">
    <div className="card">
      <img src="/w3images/team3.jpg" alt="John" style="width:100%">
      <div className="container">
        <h2>John Doe</h2>
        <p className="title">Designer</p>
        <p>Some text that describes me lorem ipsum ipsum lorem.</p>
        <p>john@example.com</p>
        <p><button className="button">Contact</button></p>
      </div>
    </div>
  </div>
</div> */}
        </React.Fragment>
    );
}