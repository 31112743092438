import * as React from 'react';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { ConfirmationDialog } from '../dialog/dialog.component';
import { userActions } from '../../Reducer';

const drawerWidth = 240;
const navItems = ['Home','Settings', 'AboutUs', 'Contact'];

export default function NavBar(props: any) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isdialog, setIsDialog] = React.useState(false);
    const navigate = useNavigate();
     // defining to dispatch the actions
     const dispatch: AppDispatch = useDispatch();

    const quizInProgress = useSelector((state: RootState) => state.isQuizInProgress);
    const [path, setPath] = React.useState('/Home');
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const checkIfQuizInProgress = () => {  
        if(quizInProgress === true) {
            setIsDialog(true);
        } else {
            navigate(path);
        }
        
    }

    const handleDialogClose = (result: any) => {
        if (result) {
            (path === 'Home') ? window.location.reload() : navigate(path);
            dispatch(userActions.IS_QUIZINPROGRESS(false as never));
        }
        setIsDialog(false);
    }

    const navigateTo = (path: string) => {      
        //if already in navigation path ignore checked
        if (navItems.some(item => window.location.href.includes(item)) && quizInProgress === false) {
            dispatch(userActions.IS_QUIZINPROGRESS(false as never));
            navigate(path);
        } else {
            setPath(path);
            checkIfQuizInProgress();
        }
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign:'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Q For Quizzer            </Typography>
            <Divider />
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={item + index} disablePadding>
                        <ListItemButton  key={`${item}_${index}`} onClick={() => navigateTo(item)} sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );


    return (
        <>
            <AppBar component="nav" sx={{bgcolor: '#ef5926'}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        Q For Quizzer
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => (
                            <Button key={item} onClick={() => navigateTo(item)} variant="text" className="navbar-button">
                            {item}
                          </Button>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            { isdialog &&
           <ConfirmationDialog title= 'Navigation Confirmation' message = 'Quiz is in progress. Do you want to leave the current screen?' open={true} onClose={handleDialogClose} value="ok" />
}
        </>
    );
}