import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { TextField, Button, Stack, Box, Grid } from '@mui/material';
import AlertDialog from '../alert/alertdialog.component';

const ContactForm = () => {
    const [disabled, setDisabled] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');


    const reset = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setMessage('');
    }


    const resetOkButton = () => {
        reset();
        // Re-enable form submission
        setDisabled(false);
    }

    async function handleSubmit(event: any) {
        event.preventDefault();
        const publickey = process.env.REACT_APP_EMAIL_PUBLIC_KEY;
        const serviceId: string = process.env.REACT_APP_EMAIL_SERVICE_ID || '';
        const templateId: string = process.env.REACT_APP_EMAIL_TEMPLATE_ID || '';

        // Disable form while processing submission
        setDisabled(true);

        const templateParams = {
            firstName,
            email,
            lastName,
            message
        };

        emailjs.send(serviceId, templateId, templateParams, publickey).then(
            (response) => {
                console.log(response);
                setStatus('success');
            },
            (error) => {
                console.log(error);
                setStatus('failure');
            },
        );
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <div>Email us to <a href='mailto:admin@qforquizzer.com'>admin@qforquizzer.com</a> or fill your details below and send the message.</div>
            <Grid item xs={3}>
                <div className='contactform'>
                    <h2>Contact Form</h2>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                            <TextField
                                type="text"
                                disabled={disabled}
                                variant='outlined'
                                color='secondary'
                                label="First Name"
                                onChange={e => setFirstName(e.target.value)}
                                value={firstName}
                                fullWidth
                                required
                            />
                            <TextField
                                type="text"
                                disabled={disabled}
                                variant='outlined'
                                color='secondary'
                                label="Last Name"
                                onChange={e => setLastName(e.target.value)}
                                value={lastName}
                                fullWidth
                                required
                            />
                        </Stack>
                        <TextField
                            type="email"
                            disabled={disabled}
                            variant='outlined'
                            color='secondary'
                            label="Email"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            fullWidth
                            required
                            sx={{ mb: 4 }}
                        />

                        <TextField
                            id="outlined-multiline-static"
                            disabled={disabled}
                            className="multiline-text"
                            color='secondary'
                            label="Message"
                            multiline
                            rows={4}
                            onChange={e => setMessage(e.target.value)}
                            defaultValue=""
                        />
                        <Box textAlign='center' className="contactform-submit">
                            <Button variant="outlined" disabled={disabled} color="secondary" type="submit">Submit</Button>
                        </Box>
                    </form>
                    {status === 'success' &&
                        <AlertDialog header="SUCCESS" message="contact submit is successfull!" button1="OK" reset={() => resetOkButton()} />
                    }
                    {status === 'failure' &&
                        <AlertDialog header="FAILURE" message="Uh oh. Something went wrong." button1="OK" reset={() => resetOkButton()} />
                    }
                </div>
            </Grid>
        </Grid>

    )
}

export default ContactForm;