import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import { userActions } from '../Reducer';
import { AppDispatch, RootState } from '../store';
function FinalScreen(props: any) {
  // @ts-ignore
  const score = useSelector((state: RootState) => state.score);
  const navigate = useNavigate();
  // defining to dispatch the actions
  const dispatch: AppDispatch = useDispatch();
  dispatch(userActions.IS_QUIZINPROGRESS(false as never));
  const questionCategory = useSelector((state: RootState) => state.selectedQuestionCategory);
  const questionIndex = useSelector((state: RootState) => state.index);
  const numberOfQuestions = useSelector((state: RootState) => state.options.amount_of_questions);
  const totalQuestionLength = useSelector((state: RootState) => state.totalQuestion);
  
  const replay = () => {
    const reset: any = 0;
    dispatch(userActions.SET_INDEX((questionIndex - numberOfQuestions) as never));
    dispatch(userActions.SET_SCORE(reset as never));
    props.clickEvent(questionCategory, (questionIndex -numberOfQuestions));
  }

  const navigateToSettings = () => {
    dispatch(userActions.SET_QUESTIONS(null as never));
    dispatch(userActions.SET_SCORE(0 as never));
    dispatch({
      type: 'SET_QUESTIONS',
      questions: [],
    })

    dispatch({
      type: 'SET_SCORE',
      score: 0,
    })
    navigate("/Settings")
  }

  function fetchNextSetOfQuestions() {
    dispatch(userActions.SET_SCORE(0 as never));
    props.fetchNewQuestions(questionCategory, questionIndex);
  }

  const navigateToHome = () => {
    window.location.reload();
  }

  return (
    <div className='score-container'>
      <h3>Final Score: {score}</h3>
      <div className='button-container'>
        <Button className='button' variant="contained" color="secondary" onClick={replay}>Try Again</Button>
        <Button className='button' variant="contained" color="secondary" onClick={fetchNextSetOfQuestions} disabled={totalQuestionLength === questionIndex}>Fetch New Questions</Button>
        <Button className='button' variant="contained" color="secondary" onClick={navigateToSettings}>Back to Settings</Button>
        <Button className='button' variant="contained" color="secondary" onClick={navigateToHome}>Go To Home</Button>
      </div>
    </div>
  )
}
export default FinalScreen
