
// src/Components/Settings.js

import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../Reducer';
import { useNavigate } from "react-router-dom";

import { RootState, AppDispatch } from '../../store';

function Settings() {
    const navigate = useNavigate();
   
    // replace state hooks with useSelector
    const loading = useSelector((state: RootState) => state.options.loading);
    const questionCategory = useSelector((state: RootState) => state.options.question_category);
   
    const questionDifficulty = useSelector((state: RootState) => state.options.question_difficulty);
    const numberOfQuestions = useSelector((state: RootState) => state.options.amount_of_questions);

    // defining to dispatch the actions
    const dispatch: AppDispatch = useDispatch();
    // useEffect(() => {
    //     const apiUrl = `https://opentdb.com/api_category.php`;
    //     const handleLoadingChange = (value: any) => {
    //         dispatch({
    //             type: 'CHANGE_LOADING',
    //             loading: value
    //         })
    //     }
    //     handleLoadingChange(true);
    //     fetch(apiUrl)
    //         .then((res) => res.json())
    //         .then((response) => {
    //             handleLoadingChange(false);
    //             setOptions(response.trivia_categories);
    //         });
    // }, [setOptions, dispatch]);

    // event that is called when an option is chosen
    const handleCategoryChange = (data: any) => {
        dispatch(userActions.CHANGE_CATEGORY(data.target.value));
    }

    const handleDifficultyChange = (data: any) => {
        dispatch(userActions.CHANGE_DIFFICULTY(data.target.value));
    }

    const handleNumberOfQuestions = (data: any) => {
        dispatch(userActions.CHANGE_AMOUNT(parseInt(data.target.value) as never));
    }


    const navigateToHome = () =>{
        dispatch(userActions.SET_SELECTEDCATEGORY(null as never));
        navigate('/Home');
    }

    // add select elements for categories
    if (!loading) {
        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '100vh' }}
            >
                <Grid item xs={3}>

                    <h2>Select Category:</h2>
                    <FormControl style={{ minWidth: 250 }}>
                        <InputLabel id="category-select-label" variant='outlined'
                            color='secondary'>Category</InputLabel>
                        <Select
                            variant='outlined'
                            color='secondary'
                            labelId="category-select-label"
                            id="category-simple-select"
                            value={questionCategory}
                            label="Category"
                            onChange={handleCategoryChange}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="GK">General Knowledge: India</MenuItem>
                            <MenuItem value="Maths">Maths</MenuItem>
                            <MenuItem value="Science">Science</MenuItem>
                            <MenuItem value="USHistory">US History</MenuItem>
                        </Select>
                    </FormControl>

                    <h2>Select Difficulty:</h2>
                    <FormControl style={{ minWidth: 250 }}>

                        <InputLabel id="difficulty-select-label" variant='outlined'
                            color='secondary'>Difficulty</InputLabel>
                        <Select
                            labelId="difficulty-select-label"
                            id="difficulty-simple-select"
                            value={questionDifficulty}
                            label="Difficulty"
                            variant='outlined'
                            color='secondary'
                            onChange={handleDifficultyChange}
                        >
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="easy">Easy</MenuItem>
                            <MenuItem value="medium">Medium</MenuItem>
                            <MenuItem value="hard">Hard</MenuItem>
                        </Select>

                    </FormControl>

                    <h2>Amount of Questions:</h2>
                    <FormControl style={{ minWidth: 250 }}>
                        <InputLabel id="questions-select-label" variant='outlined'
                            color='secondary'>questions:</InputLabel>
                        <Select
                            labelId="questions-select-label"
                            id="questions-simple-select"
                            value={numberOfQuestions}
                            label="Questions"
                            variant='outlined'
                            color='secondary'
                            onChange={handleNumberOfQuestions}
                        >
                            <MenuItem value="5">5</MenuItem>
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="15">15</MenuItem>
                            <MenuItem value="20">20</MenuItem>
                            <MenuItem value="25">25</MenuItem>
                            {/* <MenuItem value="50">50</MenuItem> */}
                        </Select>
                    </FormControl>
                    <Button variant="outlined" color="secondary" style={{ display: 'flex', marginTop: '20px' }}  onClick={navigateToHome}>Get Started!</Button>
                </Grid>
            </Grid>
        );
    }
    else {
        return (
            <div
                style={{
                    margin: "auto",
                    marginTop: "20%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
            </div>
        );
    }
}
export default Settings;