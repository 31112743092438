import * as React from 'react';
import {Routes, Route} from "react-router-dom";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './Components/home/home.component';
import NavBar from './Components/navbar/navbar.component';
import Contact from './Components/contact/contact.component';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import AboutUs from './Components/about/about.component';
import Settings from './Components/settings/setting.component';
// import banner from './assets/Banner1.png';
import './App.css';
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}


export default function DrawerAppBar(props: Props) {

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/AboutUs" element={ <AboutUs /> } />
          <Route path="/Settings" element={ <Settings /> } />
           {/* <Route path="/blog" element={ <Blog /> } />
      <Route path="/team" element={ <Team /> } />
      */}
     
        </Routes>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            sx={{  minWidth: "auto"}}
          >
            <BottomNavigationAction  label="Copyright &#169; 2024, Q for Quizzer. All rights reserved." />
          </BottomNavigation>
        </Paper>
      </Box>

    </div>
  );
}
