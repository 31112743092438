import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import CardComponent from '../card/quizcard.component';
import Question from "../questions/questions.component";
import FinalScreen from '../Score';
import { Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useNavigate } from "react-router-dom";
// import FetchButton from '../FetchButton';
import { userActions } from '../../Reducer';
import CircularProgress from '@mui/material/CircularProgress';

export default function Home(props: any) {
    const navigate = useNavigate();
    //const [quizEnd, setQuizEnd] = React.useState(false);
    const [isQuizsection, setIsQuizSection] = React.useState(false);
    const [questionBank, setQuestionBank] = React.useState([]);
    const [isLoader, setIsLoader] = React.useState(false);

    const questionCategory = useSelector((state: RootState) => state.options.question_category);
    const questionDifficulty = useSelector((state: RootState) => state.options.question_difficulty);
    const numberOfQuestions = useSelector((state: RootState) => state.options.amount_of_questions);
    const questionIndex = useSelector((state: RootState) => state.currentScreenIndex);
    const [maxQuestionLength, setmaxQuestionLength] = React.useState(0);
    // defining to dispatch the actions
    const dispatch: AppDispatch = useDispatch();

    const getQuestion = (id: string, qIndex: number) => {
        switch(id) {
            case 'GK':
                import('../questionbank/gkquestion')
                    .then((results: any) => {
                        setQuestions(results.default, qIndex);
                    });
                break;
            case 'Maths':
                import('../questionbank/mathsquestion')
                    .then((results: any) => {
                        setQuestions(results.default, qIndex);
                    });
                break;
            case 'Science':
                import('../questionbank/sciencequestion')
                    .then((results: any) => {
                        setQuestions(results.default, qIndex);
                    });
                break;
            case 'USHistory':
                import('../questionbank/ushistoryquestion')
                    .then((results: any) => {
                        setQuestions(results.default, qIndex);
                    });
                break;
            case 'Olympics':
                import('../questionbank/olympics2024question')
                    .then((results: any) => {
                        setQuestions(results.default, qIndex);
                    });
                break;
            case 'States':
                //US_states will be dynamic based on the selected category
                import('../questionbank/usstatesquestion')
                    .then((results: any) => {
                        const result = (results.default.length);
                        setQuestions(results.default, qIndex, true);
                        setmaxQuestionLength(result - 1);
                        dispatch(userActions.SET_TOTALQUESTIONLENGTH(result as never));
                    });
                break;
            default:
                break;
        }
    }

    const setQuestions = (results: any, qIndex: number, displayallquestion = false) => {
        setTimeout(() => {
            let selectedQuestions: any = results;
            if (!displayallquestion) {
                const filteredResults = questionDifficulty !== 'All' ? results.filter((question: any) => question.difficulty === questionDifficulty) : results;
                selectedQuestions = (numberOfQuestions < filteredResults.length) ? filteredResults.slice(qIndex, (qIndex + numberOfQuestions)) : filteredResults;
                setmaxQuestionLength(questionIndex + numberOfQuestions);
            }
            const shuffledQuestions = selectedQuestions.sort(() => Math.random() - 0.5);
            dispatch(userActions.SET_CurrentScreenIndex(0 as never));
            dispatch(userActions.SET_QUESTIONS(shuffledQuestions));
            dispatch(userActions.IS_QUIZINPROGRESS(true as never));
            setQuestionBank(shuffledQuestions);
            setIsQuizSection(true);
            setIsLoader(false);
        }, 2000);
    }

    const cardClickEvent = (id:string, qIndex:number) => {
        setIsLoader(true);
        dispatch(userActions.SET_SELECTEDCATEGORY(id as never));
        getQuestion(id, qIndex);
    };

    const navigateToSettings = () => {
        setIsQuizSection(false);
        dispatch(userActions.SET_INDEX(0 as never));
        dispatch(userActions.SET_CurrentScreenIndex(0 as never));
        dispatch(userActions.SET_SCORE(0 as never));
        navigate("/Settings");
    }


    return (
        <>
            <Box component="main" sx={{ p: 3 }}>
                <Toolbar />
                <div className="App app-container d-flex flex-column align-items-center justify-content-center">

                     {/* selected filter message */}
                    {!isLoader && !isQuizsection && <div className="filters">{`Filters: Category: ${questionCategory}, Difficulty: ${questionDifficulty}, Number of Question: ${numberOfQuestions}. To change the filters, click `}
                        <Link onClick={navigateToSettings} style={{ cursor: 'pointer' }}>settings</Link>
                        {` and select your preferred values.`}</div>}

                    {/* main screen */}
                    {!isLoader && !isQuizsection &&
                        <CardComponent clickEvent={cardClickEvent} />}

                    {/* no question message */}
                    {!isLoader && isQuizsection && questionBank.length === 0 &&
                        <div className=" app-container flex-column align-items-center justify-content-center noquestion-message">No Questions found for the selected category. Please select another category by going to <Link onClick={navigateToSettings} style={{ cursor: 'pointer' }}>{' Settings page'}</Link>
                        </div>}

                    {/* question screen */}
                    { !isLoader && isQuizsection && (questionIndex + 1 <= questionBank?.length) &&
                        <div> <Question questions={questionBank} />  </div>
                    }

                    {/* score screen */}
                    { !isLoader && isQuizsection && maxQuestionLength !== 0 && questionIndex === questionBank?.length &&               
                        <FinalScreen fetchNewQuestions={cardClickEvent} clickEvent={cardClickEvent} />
                    }

                    {/* loading screen */ }
                    {isLoader &&  
                        <div className="loading">
                             <CircularProgress color="secondary" />
                        </div>  
                    
                    }

                </div>
            </Box>
        </>
    );
}